import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Chat functionality consists out of two parts. Sending and receiving messages.
This part is focussed on receiving messages.`}</p>
    <p>{`In order to forward all incoming messages to your system we need a webhook url to send requests to.`}</p>
    <p>{`We will send all Incoming messages to your system as POST Request with an json formatted body.`}</p>
    <h3 {...{
      "id": "request-body"
    }}>{`Request Body`}</h3>
    <p>{`We will send the following parameters with each request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`messageText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`containing the text components of a message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`messageMultimedia`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`containing the multimedia content of an message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deliveryChannelId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the reference of the delivery channel where the message received our system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deliveryTarget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the name of the delivery target. e.g. name of telegram bot who received the message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contactId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`contact Identification if the message belongs to an existing contact.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Depending on the channel of the incoming message you receive additional parameters. Please read the following sections.`}</p>
    <h3 {...{
      "id": "setup-webook"
    }}>{`Setup Webook`}</h3>
    <p>{`To authenticate our system on your end, we send an authorization header which each request.
Contact our support team in order to give us your webhook url which we will then configure in our systems. `}</p>
    <p><a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=setup%20webhook&body=Dear%20Anymessage%20Team%2C%20%0AI%20want%20to%20setup%20a%20webhook%20url%20in%20order%20to%20receive%20incoming%20messages.%0A%0AMy%20company%20name%20is%20YOUR-COMPANY-NAME%0A%0APlease%20send%20all%20incoming%20messages%20to%20my%20webhook%20url%20YOUR-URL%0APlease%20authenticate%20your%20requests%20with%20the%20following%20Bearer%20token%20YOUR-Token%0A%0A%0AThanks%20in%20advance%20and%20have%20a%20nice%20day.%20%0AYOUR_NAME%20%0A"
      }}>{`contact support`}</a>{`.`}</p>
    <h2 {...{
      "id": "sms"
    }}>{`SMS`}</h2>
    <h3 {...{
      "id": "introduction-1"
    }}>{`Introduction`}</h3>
    <p>{`In order to receive SMS you need a msisdn or short code. These numbers needs to be integrated in our systems first.
Please contact our support team in order to integrate your existing numbers or use one of our existing numbers for your needs.`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=receive%20SMS&body=Dear%20Anymessage%20Team%2C%20%0AI%20read%20your%20online%20documentation%20and%20have%20the%20following%20questions%20%2F%20requests.%20%0A%0AI%20want%20to%20receive%20SMS%20%0A%0Aand%20have%20an%20existing%20msisdn%2Fshortcode.%20Please%20tell%20me%20how%20to%20integrate%20my%20numbers%20into%20your%20system.%0A%0Aand%20I%20need%20one%20of%20your%20existing%20numbers.%0A%0AThanks%20in%20advance%20and%20have%20a%20nice%20day.%20%0AYOUR_NAME%20%0A"
      }}>{`contact support`}</a>{`.`}</p>
    <h3 {...{
      "id": "send-message"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-sms-message"
      }}>{`Click here`}</a>{` for details on how to Send SMS Messages.`}</p>
    <h3 {...{
      "id": "receive-message"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`source`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the soure address of the received sms`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "telegram"
    }}>{`Telegram`}</h2>
    <h3 {...{
      "id": "introduction-2"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Telegram message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-1"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-telegram-message"
      }}>{`Click here`}</a>{` for details on how to Send Telegram Messages.`}</p>
    <h3 {...{
      "id": "receive-message-1"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`telegramId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`telegram username / profilename`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "meta--facebook"
    }}>{`Meta / Facebook`}</h2>
    <h3 {...{
      "id": "introduction-3"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Meta message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-2"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-meta-facebook-message"
      }}>{`Click here`}</a>{` for details on how to Send Meta / Facebook Messages.`}</p>
    <h3 {...{
      "id": "receive-message-2"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metaId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`in format "Firstname Lastname" of the meta profile`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "signal"
    }}>{`Signal`}</h2>
    <h3 {...{
      "id": "introduction-4"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Signal message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-3"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-signal-message"
      }}>{`Click here`}</a>{` for details on how to Send Signal Messages.`}</p>
    <h3 {...{
      "id": "receive-message-3"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`signalId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`signal user identification`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "instagram"
    }}>{`Instagram`}</h2>
    <h3 {...{
      "id": "introduction-5"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Instagram message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-4"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-instagram-business-message"
      }}>{`Click here`}</a>{` for details on how to Send Instagram Messages.`}</p>
    <h3 {...{
      "id": "receive-message-4"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`instagramId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`instagram user identification`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "whatsapp"
    }}>{`Whatsapp`}</h2>
    <h3 {...{
      "id": "introduction-6"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Whatsapp message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-5"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-whatsapp-business-message"
      }}>{`Click here`}</a>{` for details on how to Send Whatsapp Messages. `}</p>
    <h3 {...{
      "id": "receive-message-5"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`whatsappId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`whatsapp user identification`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "twitter"
    }}>{`Twitter`}</h2>
    <h3 {...{
      "id": "introduction-7"
    }}>{`Introduction`}</h3>
    <p>{`We will redirect incoming Twitter message via http to your system.`}</p>
    <h3 {...{
      "id": "send-message-6"
    }}>{`Send message`}</h3>
    <p><a parentName="p" {...{
        "href": "/send_to_contact_introduction/#send-twitter-message"
      }}>{`Click here`}</a>{` for details on how to Send Twitter Messages. `}</p>
    <h3 {...{
      "id": "receive-message-6"
    }}>{`Receive message`}</h3>
    <p>{`In addition to the `}<a parentName="p" {...{
        "href": "/chat/#request-body"
      }}>{`general parameters`}</a>{` you will receive the following optional parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`typ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`twitterId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Twitter user identification`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "calls"
    }}>{`Calls`}</h2>
    <h3 {...{
      "id": "introduction-8"
    }}>{`Introduction`}</h3>
    <p>{`In contrast to any text based messages we will redirect incoming calls via SIP to your voice system.
Like SMS you need an own msisdn (or virtual number) to receive calls or can use one of our existing numbers. `}</p>
    <p>{`Please contact our support team for details on how to obtain a virtual number from anymesage.`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=receive%20Calls&body=Dear%20Anymessage%20Team%2C%20%0A%0A%0AMy%20company%20name%20is%20YOUR-COMPANY-NAME%0A%0AI%20want%20to%20receive%20calls%0A%0Aand%20have%20an%20existing%20msisdn.%20Please%20tell%20me%20how%20to%20integrate%20my%20numbers%20into%20your%20system.%0A%0Aand%20I%20need%20one%20of%20your%20existing%20numbers.%0A%0AThanks%20in%20advance%20and%20have%20a%20nice%20day.%20%0AYOUR_NAME%20%0A%0A%0A%0A"
      }}>{`contact support`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      